import cookies from "../utils/cookies";
import { LoggerFactory } from "../utils/loggerFactory";

const log = LoggerFactory("LoginService");

const LoginService = {
  logout() {
    cookies.set("access_token", "", new Date(0));
    window.location.reload();
  },

  login() {
    let redirect = window.location.host;
    redirect = redirect.startsWith("http")
      ? redirect
      : `${window.location.protocol}//${redirect}`;
    const app = this.getApp();
    redirect = `${redirect}/?app=${app}`;
    window.location.assign(
      `https://admin.bussola.farm/login?redirect=${redirect}`
    );
  },

  getAccessToken() {
    return cookies.get("access_token");
  },

  customerData() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      this.login();
    }

    try {
      const token = this.getAccessToken().split(".")[1];

      const decodedData = Buffer.from(token, "base64").toString();

      return JSON.parse(decodedData);
    } catch (error) {
      log("customerData").error(
        "error trying to get logged user data {}",
        error
      );
    }
  },

  getApp: () => {
    return localStorage.getItem("app") ?? "mapeixe";
  },

  getMode: () => {
    return localStorage.getItem("mode") ?? "visitor";
  },
};

export default LoginService;
