import React, { useCallback, useState } from 'react';
import {
  detailsUpdatedListener,
  loadingAnnotationListener
} from '../../listeners';
import DetailsService from '../../services/DetailsService';

const AnnotationField = ({ annotation, id, detailsId }) => {
  const [fieldName, setName] = useState(annotation?.name ?? '');
  const [fieldValue, setValue] = useState(annotation?.value ?? '');
  const [isPrivate, setPrivacy] = useState(annotation?.isPrivate ?? false);
  const [edited, setEdited] = useState(false);

  const handleNameChange = useCallback(event => {
    setName(event.target.value);
    setEdited(true);
    loadingAnnotationListener.publish(true);
  }, []);

  const handleValueChange = useCallback(event => {
    setValue(event.target.value);
    setEdited(true);
    loadingAnnotationListener.publish(true);
  }, []);

  const handleSwitch = useCallback(() => {
    setPrivacy(!isPrivate);
    setEdited(true);
    loadingAnnotationListener.publish(true);
  }, [isPrivate]);

  const handleSaveAnnotation = useCallback(() => {
    loadingAnnotationListener.publish(true);
    const newAnnotion = {
      name: fieldName,
      value: fieldValue,
      isPrivate
    };

    if (id !== 'new-annotation') {
      newAnnotion.id = id;
    }

    (async () => {
      const response = await DetailsService.upsertAnnotation(
        detailsId,
        newAnnotion
      );
      loadingAnnotationListener.publish(false);
      detailsUpdatedListener.publish(response);
      setEdited(false);
    })();
  }, [fieldName, fieldValue, isPrivate, detailsId, id]);

  const handleCancelAnnotation = useCallback(() => {
    loadingAnnotationListener.publish(true);
    (async () => {
      const response = await DetailsService.find(
        window.selectedAnnotationLocation
      );
      loadingAnnotationListener.publish(false);
      detailsUpdatedListener.publish(response);
      setEdited(false);
    })();
  }, []);

  const handleRemoveAnnotation = useCallback(() => {
    loadingAnnotationListener.publish(true);

    (async () => {
      const response = await DetailsService.deleteAnnotation(detailsId, id);
      loadingAnnotationListener.publish(false);
      detailsUpdatedListener.publish(response);
    })();
  }, [detailsId, id]);

  const renderActionButton = () => {
    if (edited) {
      return (
        <div className="annotation-actions">
          <button
            className="save-annotation"
            onClick={handleSaveAnnotation}
            title="Salvar"
          >
            &#10003;
          </button>
          <button
            className="remove-annotation"
            onClick={handleCancelAnnotation}
            title="Cancelar"
          >
            &times;
          </button>
        </div>
      );
    }

    return (
      <button
        className="remove-annotation"
        onClick={handleRemoveAnnotation}
        title="Excluir"
      >
        &times;
      </button>
    );
  };

  const renderValueField = () => {
    return (
      <textarea
        type="text"
        placeholder="Anotação em texto ou números"
        value={fieldValue}
        onChange={handleValueChange}
      />
    );
  };

  const renderPrivacyField = () => {
    return (
      <label className="switch">
        <input type="checkbox" checked={!isPrivate} onChange={handleSwitch} />
        <span className="slider round"></span>
      </label>
    );
  };

  return (
    <div className="AnnotationField">
      {renderActionButton()}
      <input
        type="text"
        placeholder="Tipo de informação a ser registrada"
        value={fieldName}
        onChange={handleNameChange}
      />
      {renderValueField()}
      {renderPrivacyField()}
    </div>
  );
};

export default AnnotationField;
