import { useCallback, useEffect, useState } from "react";
import { reportPrintedListener } from "../../listeners";
import LoginService from "../../services/LoginService";
import ReportService from "../../services/ReportService";
import "./SendReportByEmail.css";

const SendReportByEmail = () => {
  const [show, toggleVisibility] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (show) {
      setEmail(LoginService.customerData().email);
    }
  }, [show]);

  reportPrintedListener.subscribe("SendReportByEmail", () => {
    toggleVisibility(true);
  });

  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleClose = useCallback(() => {
    toggleVisibility(false);
  }, []);

  const handleConfirmation = useCallback(() => {
    ReportService.sendReportByEmail(email, window.recentlyPrintedReport);
  }, [email]);

  return (
    <div className={`srbe-background${show ? "" : " hidden"}`}>
      <div className="srbe-content">
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <div className="title">
          <p>Deseja enviar o relatório por e-mail?</p>
        </div>
        <input
          className="email"
          type="email"
          value={email}
          placeholder="email@email.com"
          onChange={handleEmailChange}
        />
        <div className="button-wraper">
          <button
            className="option-button confirmation"
            onClick={handleConfirmation}
          >
            Sim
          </button>
          <button className="option-button negation" onClick={handleClose}>
            Não
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendReportByEmail;
