import React, { useEffect } from "react";
import "./App.css";
import MainDrawerPage from "./components/drawer";
import LoginService from "./services/LoginService";
import cookies from "./utils/cookies";

const App = () => {
  useEffect(() => {
    const url = new URL(window.location.href);

    url.searchParams.forEach((v, k) => {
      if (k === "access_token") {
        const expires = new Date(
          JSON.parse(Buffer.from(v.split(".")[1], "base64").toString("ascii"))
            .exp * 1000
        );
        cookies.set("access_token", v, expires);
      } else if (k === "mode") {
        localStorage.setItem("mode", v);
      } else if (k === "app") {
        localStorage.setItem("app", v);
      }
    });

    const accessToken = cookies.get("access_token");

    if (
      !accessToken &&
      (localStorage.getItem("mode") ?? "visitor") === "logged"
    ) {
      return LoginService.login();
    }

    if (url.searchParams.size > 0) {
      return window.location.assign("/");
    }
  }, []);

  return <MainDrawerPage />;
};

export default App;
