const consumers = {};

/**
 * A simple messages broker for annotations fields loading view
 */
const reportPrintedListener = {
  /**
   * Register a function to run when a message is published to this channel
   * @param {string} source
   * @param {function} listener listen to report recently printed events
   */
  subscribe(source, listener) {
    consumers[source] = listener;
  },

  /**
   * trigger this channel
   */
  publish() {
    Object.keys(consumers).forEach(source => {
      const listen = consumers[source];
      listen();
    });
  }
};

export default reportPrintedListener;
