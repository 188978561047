const consumers = {};

/**
 * A simple messages broker for already visited points updates
 */
const alreadyVisitedPointsUpdatedListener = {
  /**
   * Register a function to run when a message is published to this channel
   * @param {string} source
   * @param {function({location: string})} listener listen to location updated events
   */
  subscribe(source, listener) {
    consumers[source] = listener;
  },

  /**
   * Publish a message to this channel
   * @param {string} message
   */
  publish(message) {
    Object.keys(consumers).forEach(source => {
      const listen = consumers[source];
      listen(message);
    });
  }
};

export default alreadyVisitedPointsUpdatedListener;
