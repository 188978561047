import L from "leaflet";
import DetailsServices from "../services/DetailsService";
import LoginService from "../services/LoginService";
import PointDetails from "./PointDetails";

window.toggleAlreadyVisited = async (visited) => {
  DetailsServices.updatedVisited(window.selectedAnnotationLocation, visited);
};

L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
  onAdd: function (map) {
    L.TileLayer.WMS.prototype.onAdd.call(this, map);
    map.on("click", this.getFeatureInfo, this);
  },

  onRemove: function (map) {
    L.TileLayer.WMS.prototype.onRemove.call(this, map);
    map.off("click", this.getFeatureInfo, this);
  },

  getFeatureInfo: function (evt) {
    var url = this.getFeatureInfoUrl(evt.latlng),
      showResults = L.Util.bind(this.showGetFeatureInfo, this);
    fetch(url)
      .then((d) => d.json())
      .then((r) => showResults("err", evt.latlng, r));
  },

  getFeatureInfoUrl: function (latlng) {
    var point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
      size = this._map.getSize(),
      params = {
        request: "GetFeatureInfo",
        service: "WMS",
        srs: "EPSG:4326",
        styles: this.wmsParams.styles,
        transparent: this.wmsParams.transparent,
        version: this.wmsParams.version,
        format: this.wmsParams.format,
        bbox: this._map.getBounds().toBBoxString(),
        height: size.y,
        width: size.x,
        layers: this.wmsParams.layers,
        query_layers: this.wmsParams.layers,
        info_format: "application/json",
      };

    params[params.version === "1.3.0" ? "i" : "x"] = Math.round(point.x);
    params[params.version === "1.3.0" ? "j" : "y"] = Math.round(point.y);

    var url = this._url + L.Util.getParamString(params, this._url, true);

    if (typeof this.wmsParams.proxy !== "undefined") {
      if (typeof this.wmsParams.proxyParamName !== "undefined")
        this.wmsParams.proxyParamName = "url";

      const _proxy =
        this.wmsParams.proxy + "?" + this.wmsParams.proxyParamName + "=";

      url = _proxy + encodeURIComponent(url);
    }

    return url;
  },

  showGetFeatureInfo: function (err, latlng, content) {
    PointDetails({
      isOffline: window.isOffline,
      location: window.selectedAnnotationLocation,
      content,
      appName: LoginService.getApp(),
    }).then((component) => {
      if (component) {
        L.popup({ maxWidth: 800 })
          .setLatLng(latlng)
          .setContent(component)
          .openOn(this._map);
      }
    });
  },
});

L.tileLayer.betterWms = function (url, options) {
  return new L.TileLayer.BetterWMS(url, options);
};
