import {
  Checkbox,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Slider,
  Typography,
  withStyles
} from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { pointsFiltersListener } from '../listeners';
import LoginService from '../services/LoginService';
import { Details } from './Details';
import { Loading } from './Loading';
import Map from './map';
import { SendReportByEmail } from './SendReportByEmail';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));
const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

const QUERY_BY_APP = {
  mapeixe: ({ startHa, endHa, startNa, endNa }) => {
    return `area_ha >= ${startHa} AND area_ha <= ${endHa} AND areaina >= ${startNa} AND areaina <= ${endNa}`;
  },
  mapboi: ({ startHa, endHa }) => {
    return `area_ha >= ${startHa} AND area_ha <= ${endHa}`;
  },
};

const QUERY_VALUES_BY_APP = {
  mapeixe: ({ startHa, endHa, startNa, endNa, startStatic, endStatic }) => {
    return { startHa, endHa, startNa, endNa };
  },
  mapboi: ({ startHa, endHa, startNa, endNa, startStatic, endStatic }) => {
    return { startHa, endHa, startStatic, endStatic };
  },
};

export default function MainDrawerPage() {
  const classes = useStyles();
  const open = true;

  const [checkboxRoute, setCheckboxRoute] = useState(true);

  const routingControl = React.useRef();
  const [valueSlider1, setValueSlider1] = useState([0, 126]);
  const [valueSlider2, setValueSlider2] = useState([0, 100]);

  const routeContent = useRef([]);
  const routeContent1 = useRef([]);
  const routeContent2 = useRef([]);

  const handleChangeHec = (event, newValue) => {
    setValueSlider1(newValue);
  };
  const handleChangePer = (event, newValue) => {
    setValueSlider2(newValue);
  };

  useEffect(() => {
    const [startHa, endHa] = valueSlider1;
    const [startNa, endNa] = valueSlider2;

    const filters = QUERY_BY_APP[LoginService.getApp()]({startHa, endHa, startNa, endNa})
    const filterValues = QUERY_VALUES_BY_APP[LoginService.getApp()]({startHa, endHa, startNa, endNa, startStatic: startNa, endStatic: endNa})
    
    pointsFiltersListener.publish({ filters, filterValues });
  }, [valueSlider1, valueSlider2]);

  const changeLayer = (e, name) => {
    if (name === 'routing') {
      setCheckboxRoute(e.target.checked);
      if (e.target.checked) {
        routingControl.current.show();
      } else {
        routingControl.current.hide();
        routeContent.current = [];
        routeContent1.current = [];
        routeContent2.current = [];
        routingControl.current.setWaypoints([
          ...routeContent.current,
          ...routeContent2.current,
          ...routeContent1.current
        ]);
      }
    }
  };

  const renderAppDependency = () => {
    return LoginService.getApp() === 'mapboi' ? (
      <List>
        <ListItem button key={'icon4'}>
          <ListItemIcon>
            <DirectionsIcon />
          </ListItemIcon>
          <ListItemText primary={'Obter rota'} checked={checkboxRoute} />
          <Checkbox onChange={e => changeLayer(e, 'routing')} />
        </ListItem>
        <Divider />
        <div style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          <Typography>Área do confinamento (hectares)</Typography>
          <PrettoSlider
            style={{ width: '88%' }}
            value={valueSlider1}
            onChange={handleChangeHec}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={126}
          />
        </div>
        <Divider />
        <div style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          <Typography> Capacidade estática </Typography>
          <PrettoSlider
            style={{ width: '88%' }}
            value={valueSlider2}
            onChange={handleChangePer}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={35000}
            min={0}
          />
        </div>
      </List>
    ) : (
      <List>
        <ListItem button key={'icon4'}>
          <ListItemIcon>
            <DirectionsIcon />
          </ListItemIcon>
          <ListItemText primary={'Obter rota'} checked={checkboxRoute} />
          <Checkbox onChange={e => changeLayer(e, 'routing')} />
        </ListItem>
        <Divider />
        <div style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          <Typography>Área da unidade piscícola (hectares)</Typography>
          <PrettoSlider
            style={{ width: '88%' }}
            value={valueSlider1}
            onChange={handleChangeHec}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={126}
          />
        </div>
        <Divider />
        <div style={{ textAlign: 'center' }}>
          <Typography>
            Parcela da unidade piscícola fora de produção (%)
          </Typography>
          <PrettoSlider
            style={{ width: '88%' }}
            value={valueSlider2}
            onChange={handleChangePer}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
        </div>
      </List>
    );
  };

  const renderLogo = () => {
    return (
      <center style={{ overflow: 'hidden' }}>
        <img style={{ overflow: 'hidden' }} src="images/logo.jpg" alt="Logo" />
        <center>bussola.farm</center>
        <center style={{marginTop: '1rem'}}>
          <strong>
            {LoginService.getApp() === 'mapboi' ? 'MapBoi' : 'MaPeixe'}
          </strong>
        </center>
      </center>
    );
  };

  return (
    <div className={classes.root}>
      <SendReportByEmail />
      <Loading />
      <Details />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Divider />
        {renderAppDependency()}
        {renderLogo()}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <Map
          routingControl={routingControl}
          setCheckboxRoute={setCheckboxRoute}
          routeContent={routeContent}
          routeContent1={routeContent1}
          routeContent2={routeContent2}
        />
      </main>
    </div>
  );
}
