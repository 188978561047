import axios from "axios";
import { alreadyVisitedPointsUpdatedListener } from "../listeners";
import { LoggerFactory } from "../utils/loggerFactory";
import LoginService from "./LoginService";
const log = LoggerFactory("DetailsService");

const api = axios.create({ baseURL: "https://details.bussola.farm" });

const DetailsService = {
  async upsertAnnotation(detailsId, annotation) {
    const body = Buffer.from(JSON.stringify(annotation, null, 0), 'ascii').toString('base64')
    
    try {
      const response = await api.get(`/update/${detailsId}/annotations?body=${body}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoginService.getAccessToken()}`,
        },
      });

      const { data, status } = response;

      if (status !== 200) return null;

      return data;
    } catch (error) {
      log("upsertAnnotation").error(
        "error trying to upsert an annotation: {}",
        error
      );
      return null;
    }
  },

  async deleteAnnotation(detailsId, annotationId) {
    try {
      const response = await api.get(
        `/delete/${detailsId}/annotations/${annotationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${LoginService.getAccessToken()}`,
          },
        }
      );

      const { data, status } = response;

      if (status !== 200) return null;

      return data;
    } catch (error) {
      log("deleteAnnotation").error(
        "error trying to delete an annotation: {}",
        error
      );
      return null;
    }
  },

  async find(location) {
    try {
      const response = await api.get(`/from-location/${location}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoginService.getAccessToken()}`,
        },
      });

      const { data, status } = response;

      if (status !== 200) return null;

      return data;
    } catch (error) {
      log("find").error("error trying to find details by location: {}", error);
      return null;
    }
  },

  async detailsVisited(location) {
    try {
      const response = await api.get(`/from-location/${location}/visited`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${LoginService.getAccessToken()}`,
        },
      });

      const { data, status } = response;
      if (status !== 200) return null;

      return data?.alreadyVisited;
    } catch (error) {
      log("detailsVisited").error(
        "error trying to verify if this point is already visited: {}",
        error
      );
      return false;
    }
  },

  async updatedVisited(location, visited) {
    try {
      const response = await api.get(
        `/from-location/${location}/visited/${visited}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${LoginService.getAccessToken()}`,
          },
        }
      );

      const { data, status } = response;
      if (status !== 200) return null;

      alreadyVisitedPointsUpdatedListener.publish({ location });

      return data;
    } catch (error) {
      log("updatedVisited").error(
        "error trying to update point visitation status: {}",
        error
      );
      return null;
    }
  },

  async findAlreadyVisitedPoints(coordinates, filters) {
    try {
      const request = JSON.stringify(coordinates, null, 0);

      const response = await api.get(
        `/all-already-visited?coordinates=${request}&filters=${filters}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${LoginService.getAccessToken()}`,
          },
        }
      );

      const { data, status } = response;
      if (status !== 200) return null;

      return data ?? null;
    } catch (error) {
      log("findAlreadyVisitedPoints").error(
        "error trying to find already visited points for coordinates: {}",
        error
      );
      return null;
    }
  },
};

export default DetailsService;
