import DetailsService from "../../services/DetailsService";

const POINTS_SIZE_PER_APP = {
  mapeixe: {
    mean: 5,
    great: 20,
  },
  mapboi: {
    mean: 2,
    great: 10,
  },
};

const getSizeFromAreaHA = (area, appName) => {
  const { mean, great } = POINTS_SIZE_PER_APP[appName];
  if (area >= great) return "Grande";
  if (area >= mean) return "Medio";

  return "Pequeno";
};

const parseProperties = (properties, appName) => {
  const parsedProperties = {
    Id: properties.local,
    Município: properties.muni,
    Tamanho: properties.tamanho,
  };

  const areaHA = parseFloat(properties.area_ha).toFixed(2);

  if (appName === "mapboi") {
    parsedProperties["ID do confinamento"] = properties.unidade;
    parsedProperties["Área (hectares)"] = areaHA;
  } else if (appName === "mapeixe") {
    parsedProperties["Unidade piscícola"] = properties.unidade;
    parsedProperties["Área da unidade piscícola (hectares)"] = areaHA;
    parsedProperties["Hectares em produção"] = parseFloat(
      properties.area_ati
    ).toFixed(2);
    parsedProperties["Hectares fora de produção"] = parseFloat(
      properties.area_ina
    ).toFixed(2);
    parsedProperties["Parcela da unidade piscícola fora de produção (%)"] =
      parseFloat(properties.areaina).toFixed(2);
  }

  const attributes = properties?.atributos?.split(";") ?? [];
  attributes.forEach((attribute) => {
    const [title, value] = attribute.split(":");
    parsedProperties[title] = value;
  });

  return Object.entries(parsedProperties)
    .map(
      ([key, value]) =>
        `<tr><td style="font-weight: bold">${key}</td><td>${value}</td></tr>`
    )
    .join("\n");
};

const PointDetails = async ({ isOffline, content, appName }) => {
  if (isOffline) {
    return `<div className="toggle">
                <p>Offline</p>
            </div>`;
  }

  try {
    content.features[0].properties.tamanho = getSizeFromAreaHA(
      content.features[0].properties.area_ha,
      appName
    );

    const location =
      content.features[0].id.split(".")[0] +
      "." +
      content.features[0].properties.unidade;

    content.features[0].properties.local = location;

    window.selectedAnnotationLocation = location;

    const properties = parseProperties(content.features[0].properties, appName);

    const alreadyVisited = await DetailsService.detailsVisited(location);
    window.toggleAlreadyVisited(alreadyVisited);

    return `<div>
                <div class="toggle">
                  <label class="switch">
                    <input
                      type="checkbox"
                      onchange="window.toggleAlreadyVisited(event.target.checked);"
                      id="already-visited-toggle"
                      ${alreadyVisited ? "checked" : ""} />
                    <span class="slider round"></span>
                  </label>
                  <p>Já visitei</p>
                </div>

                <table class="point-details-table">
                  ${properties}
                </table>
                <br />
                <button class="add-annotations" onclick="window.toggleAnnotations(true);">Anotações</button>
                <button title="Imprimir Detalhes" class="print-details" onclick="window.printDetails();">🖨</button>
            </div>`;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default PointDetails;
