import { LoggerFactory } from "../utils/loggerFactory";

const log = LoggerFactory('detailsUpdatedListener');

const consumers = {};

/**
 * A simple messages broker for points details updates
 */
const detailsUpdatedListener = {
  /**
   * Register a function to run when a message is published to this channel
   * @param {string} source 
   * @param {function} listener the body of this function is a details with
   * annotations object got from response of a details REST operation
   */
  subscribe(source, listener) {
    consumers[source] = listener;
  },

  /**
   * Publish a details object previous got from a REST operation over details
   * @param {*} message 
   */
  publish(message) {
    Object.keys(consumers).forEach(source => {
      log('publish').info(
        '{} listen to message {}',
        source,
        JSON.stringify({ message }, null, 0)
      );
      const listen = consumers[source];
      listen(message);
    });
  }
};

export default detailsUpdatedListener;
