import React, { useState } from 'react';
import { loadingPrintListener, remainingPrintsListener } from '../../listeners';
import './Loading.css';

const Loading = () => {
  const [enabled, toggleEnabled] = useState(false);

  loadingPrintListener.subscribe('Loading', loading => {
    toggleEnabled(loading);
  });

  remainingPrintsListener.subscribe('Loading', remaining => {
    if (remaining === 0) {
      alert(`Você não tem impressões disponíveis`);
    } else {
      alert(`Imprimindo 1 relatório de ${remaining} relatórios restantes`);
    }
  });

  return (
    <div className={`loading-background${enabled ? '' : ' hidden'}`}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
