import { LoggerFactory } from '../utils/loggerFactory';

const log = LoggerFactory('loadingAnnotationListener');

const consumers = {};

/**
 * A simple messages broker for annotations fields loading view
 */
const loadingAnnotationListener = {
  /**
   * Register a function to run when a message is published to this channel
   * @param {string} source
   * @param {function(boolean)} listener listen to true or false events
   */
  subscribe(source, listener) {
    consumers[source] = listener;
  },

  /**
   * Publish a message to this channel
   * @param {boolean} message
   */
  publish(message) {
    Object.keys(consumers).forEach(source => {
      log('publish').info(
        '{} listen to message: {}',
        source,
        JSON.stringify({ message }, null, 0)
      );
      const listen = consumers[source];
      listen(message);
    });
  }
};

export default loadingAnnotationListener;
