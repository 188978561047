import axios from "axios";
import { LoggerFactory } from "../utils/loggerFactory";
import LoginService from "./LoginService";

const log = LoggerFactory("GeolocationService");
const api = axios.create({
  baseURL: "https://geolocation.bussola.farm",
});

export const VISITOR_CONFIG = {
  center: [-11.426638506524633, -61.948932695435424],
  perimetersLayer: ["bussola:municipios_perimetros"],
  pointsLayer: ["bussola:municipios_complexos_centroides_castanheiras"],
  polygonsLayer: ["bussola:complexos_castanheiras"],
};

const GeolocationService = {
  async fetchDataByArea({ geojson, filters }) {
    const body = Buffer.from(
      JSON.stringify({ geojson, filters }, null, 0),
      "utf-8"
    ).toString("base64");

    return api
      .get(`/data-by-area?body=${body}`, {
        headers: {
          Authorization: `Bearer ${LoginService.getAccessToken()}`,
          'x-app': LoginService.getApp(),
        },
      })
      .then((response) => {
        if (response.status === 200) return response.data;
        return null;
      })
      .catch((error) => {
        log("fetchDataByArea").error("{}", error.message ?? error);
        return null;
      });
  },

  async getRandomPoint() {
    return api
      .get("/points/random", {
        headers: {
          Authorization: `Bearer ${LoginService.getAccessToken()}`
        },
      })
      .then((response) => {
        if (response.status === 200) return response.data;
        return null;
      })
      .catch((error) => {
        log("getRandomPoint").error("{}", error.message ?? error);
        return null;
      });
  },

  async getCustomerBasedLayers(customerData) {
    if (LoginService.getMode() === "visitor") {
      return VISITOR_CONFIG;
    }

    const center = await this.getRandomPoint();

    const roles = customerData.roles.split(",");

    const perimetersLayer = roles.map(
      (role) =>
        `bussola:customer_app_perimeters_${role
          .replace("ROLE_", "")
          .toLowerCase()}`
    );

    const pointsLayer = roles.map(
      (role) =>
        `bussola:${LoginService.getApp()}_customer_app_points_${role
          .replace("ROLE_", "")
          .toLowerCase()}`
    );

    const polygonsLayer = roles.map(
      (role) =>
        `bussola:${LoginService.getApp()}_customer_app_polygons_${role
          .replace("ROLE_", "")
          .toLowerCase()}`
    );

    return {
      center: center ?? [-11.426638506524633, -61.948932695435424],
      perimetersLayer,
      pointsLayer,
      polygonsLayer,
    };
  },
};

export default GeolocationService;
