/**
 * Be aware of this imports sorts, L from leaflet must came after React
 * but before all other leaflet packages references (imports)
 */
/* eslint sort-imports:["off"] */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import L from "leaflet";

import "leaflet-contextmenu";
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import "leaflet-fullscreen/";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-measure/dist/leaflet-measure.css";
import "leaflet-measure/dist/leaflet-measure.pt_BR";
import "leaflet-routing-machine/";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine/src/localization";
import "leaflet.vectorgrid/";
import "leaflet/dist/leaflet.css";

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import "./Control.Geocoder";
import "./L.TileLayer.BetterWMS";
import "./Leaflet.Icon.Glyph";

import {
  alreadyVisitedPointsUpdatedListener,
  loadingPrintListener,
  pointsFiltersListener,
  remainingPrintsListener,
  reportPrintedListener,
} from "../listeners";
import DetailsService from "../services/DetailsService";
import GeolocationService, {
  VISITOR_CONFIG,
} from "../services/GeolocationService";
import LoginService from "../services/LoginService";
import ReportService from "../services/ReportService";
import { LoggerFactory } from "../utils/loggerFactory";

const log = LoggerFactory("map.js");

const waypoints = [];

const LOCATION_DEBOUNCE_TIME = 1000;

const icon = L.icon({
  iconUrl: "images/pin.png",
  iconSize: [25, 32],
  iconAnchor: [20, 20],
  markerColor: "transparent",
});

const VISITED_ICON_SIZE = 16;

const alreadyVisitedIcon = {
  grande: () =>
    L.icon({
      iconUrl: "images/blue_check.png",
      iconSize: [VISITED_ICON_SIZE, VISITED_ICON_SIZE],
      iconAnchor: [VISITED_ICON_SIZE / 2, VISITED_ICON_SIZE / 2],
    }),
  medio: () =>
    L.icon({
      iconUrl: "images/yellow_check.png",
      iconSize: [VISITED_ICON_SIZE, VISITED_ICON_SIZE],
      iconAnchor: [VISITED_ICON_SIZE / 2, VISITED_ICON_SIZE / 2],
    }),
  pequeno: () =>
    L.icon({
      iconUrl: "images/red_check.png",
      iconSize: [VISITED_ICON_SIZE, VISITED_ICON_SIZE],
      iconAnchor: [VISITED_ICON_SIZE / 2, VISITED_ICON_SIZE / 2],
    }),
};

const alreadyVisitedMarkers = [];

const Map = ({
  routingControl,
  setCheckboxRoute,
  routeContent,
  routeContent1,
  routeContent2,
}) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef();
  const [layer, setLayer] = useState();
  const [baseLayer, setBaseLayer] = useState(true);
  const [user, setUser] = useState(null);
  const [geoserverConfig, setGeoserverConfig] = useState(VISITOR_CONFIG);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [mapReady, setMapReady] = useState(false);
  const [showMarker, setShowMarker] = useState(false);
  const [updateLocationDebounce, setUpdateLocationDebounce] = useState(null);
  const [dataLayer, setDataLayer] = useState(null);
  const [showAlreadyVisitedPoints, toggleShowAlreadyVisitedPoints] =
    useState(true);

  const token =
    "pk.eyJ1IjoiYnVzc29sYWZhcm0iLCJhIjoiY2tuNGV3MXRlMGs4MjJwbnE3NjZ2cjlzZCJ9.EMru8wHPV0S_rUyWEIX6gw";

  alreadyVisitedPointsUpdatedListener.subscribe("map", ({ location }) => {
    fetchAndMarkAlreadyVisitedPoints(location);
  });

  pointsFiltersListener.subscribe(
    "map-filters",
    ({ filters, filterValues }) => {
      window.pointsFilters = filters;
      window.pointsFilterValues = filterValues;
      if (dataLayer && geoserverConfig) {
        dataLayer.setParams({
          CQL_FILTER: geoserverConfig.pointsLayer.map(() => filters).join(";"),
        });
      }
      alreadyVisitedPointsUpdatedListener.publish({ location: "filters" });
    }
  );

  const fetchAndMarkAlreadyVisitedPoints = useCallback(
    (_source) => {
      if (window.alreadyVisitedRequest) {
        clearTimeout(window.alreadyVisitedRequest);
      }
      if (showAlreadyVisitedPoints) {
        window.alreadyVisitedRequest = setTimeout(() => {
          const defaultBoundPoint = () => {
            return { lat: 0, lng: 0 };
          };
          const bounds = window.map?.getBounds() ?? {
            getNorthWest: defaultBoundPoint,
            getNorthEast: defaultBoundPoint,
            getSouthEast: defaultBoundPoint,
            getSouthWest: defaultBoundPoint,
          };
          const coordinates = [
            bounds.getNorthWest(),
            bounds.getNorthEast(),
            bounds.getSouthEast(),
            bounds.getSouthWest(),
            bounds.getNorthWest(),
          ].map((boundPoint) => [boundPoint.lng, boundPoint.lat]);

          (async () => {
            while (alreadyVisitedMarkers.length > 0) {
              const mark = alreadyVisitedMarkers.pop();
              mark.remove();
            }
            const response = await DetailsService.findAlreadyVisitedPoints(
              coordinates,
              window.pointsFilters
            );
            if (Array.isArray(response)) {
              response.forEach((point) => {
                const newIcon = alreadyVisitedIcon[point.size.toLowerCase()]();
                const place = [point.lat, point.lng];
                const newMarker = L.marker(place, {
                  icon: newIcon,
                  bubblingMouseEvents: true,
                }).addTo(window.map);
                alreadyVisitedMarkers.push(newMarker);
              });
            }
          })();
        }, 1000);
      }
    },
    [showAlreadyVisitedPoints]
  );

  const printReport = (reportData) => {
    return ReportService.printReport(reportData)
      .then((resp) => resp.body.getReader())
      .then((reader) => {
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((file) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
          const base64Data = fileReader.result;
          window.recentlyPrintedReport = base64Data;
        };

        saveAs(file, "report.pdf");
      })
      .then(() => {
        loadingPrintListener.publish(false);
        reportPrintedListener.publish();
      })
      .catch((err) => {
        log("printReport").error("{}", err.message ?? err);
        loadingPrintListener.publish(false);
      });
  };

  const calculateRemainingPrints = async () => {
    const response = await ReportService.verifyHowManyReports();
    if (!response) {
      loadingPrintListener.publish(false);
      return false;
    }

    return response
      .json()
      .then((data) => {
        remainingPrintsListener.publish(data.remainingPrints ?? 0);
        return data.remainingPrints ?? 0;
      })
      .then((remainingPrints) => {
        if (remainingPrints === 0) {
          loadingPrintListener.publish(false);
          return false;
        }
        return true;
      });
  };

  window.printDetails = () => {
    loadingPrintListener.publish(true);
    calculateRemainingPrints()
      .then((mustPrint) => {
        if (mustPrint) {
          return DetailsService.find(window.selectedAnnotationLocation);
        }
        throw new Error("No reports authorized");
      })
      .then((response) => {
        return html2canvas(document.getElementById("map"))
          .then((canvas) => canvas.toDataURL())
          .then((image) => {
            const table = {
              "Local:": response.location,
              "Empresa:": response.company,
              "App:": response.app,
              "Já visitou:": response.alreadyVisited ? "Sim" : "Não",
            };

            for (const data of response.annotations) {
              table[data.name] = data.value;
            }

            return {
              table,
              image,
            };
          });
      })
      .then(printReport)
      .catch((err) => {
        log("printDetails").error("{}", err.message ?? err);
        loadingPrintListener.publish(false);
      });
  };

  window.printMeasures = () => {
    loadingPrintListener.publish(true);
    calculateRemainingPrints().then((mustPrint) => {
      if (mustPrint) {
        const popupData = document.getElementsByClassName(
          "leaflet-popup-content"
        )[0];

        const data = popupData.children[0].children;
        const area = data[1].innerText;
        const perimeter = data[2].innerText;

        return html2canvas(document.getElementsByTagName("main")[0])
          .then((canvas) => canvas.toDataURL())
          .then((image) => {
            const table = {
              Área: area,
              Perímetro: perimeter,
            };

            return {
              table,
              image,
            };
          })
          .then(printReport);
      }
    });
  };

  window.printDataCalc = () => {
    loadingPrintListener.publish(true);
    calculateRemainingPrints().then((mustPrint) => {
      if (mustPrint) {
        const data = Array.from(
          document.getElementsByClassName("leaflet-popup-content")[0]
            .children[0].children[0].children
        ).map((tr) => Array.from(tr.children).map((td) => td.innerText));

        const table = {};
        for (const item of data) {
          table[item[0]] = item[1];
        }

        return html2canvas(document.getElementsByTagName("main")[0])
          .then((canvas) => canvas.toDataURL())
          .then((image) => {
            return {
              table,
              image,
            };
          })
          .then(printReport);
      }
    });
  };

  useEffect(() => {
    if (dataLayer && geoserverConfig) {
      dataLayer.setParams({
        CQL_FILTER: geoserverConfig.pointsLayer
          .map(() => window.pointsFilters)
          .join(";"),
      });
    }
  }, [dataLayer, geoserverConfig]);

  useEffect(() => {
    if (showAlreadyVisitedPoints) {
      alreadyVisitedPointsUpdatedListener.publish({ location: "layer" });
    } else {
      while (alreadyVisitedMarkers.length > 0) {
        const mark = alreadyVisitedMarkers.pop();
        mark.remove();
      }
    }
  }, [showAlreadyVisitedPoints]);

  useEffect(() => {
    const callRouting = () => {
      routingControl.current.setWaypoints([
        ...routeContent.current,
        ...routeContent2.current,
        ...routeContent1.current,
      ]);
      routingControl.current.show();
      setCheckboxRoute(true);
    };
    const setStart = (e) => {
      routeContent.current[0] = e.latlng;
      callRouting();
    };
    const setWayPoint = (e) => {
      routeContent2.current.push(e.latlng);
      callRouting();
    };
    const setEnd = (e) => {
      routeContent1.current[0] = e.latlng;
      callRouting();
    };
    const _map = L.map(mapContainer.current, {
      contextmenu: true,
      contextmenuItems: [
        {
          text: "Rota: origem",
          callback: setStart,
        },
        {
          text: "Rota: destino",
          callback: setWayPoint,
        },
        {
          text: "Rota: destino final",
          callback: setEnd,
        },
      ],
    })
      .on("load", (event) => {
        setMapReady(true);
        alreadyVisitedPointsUpdatedListener.publish({ location: "load" });
      })
      .setView(geoserverConfig.center, 12);

    _map.addControl(
      new L.Control.Fullscreen({
        title: {
          false: "Tela cheia",
          true: "Sair da Tela Cheia",
        },
      })
    );
    const measureControl = new L.Control.Measure({
      position: "topleft",
      primaryLengthUnit: "meters",
      secondaryLengthUnit: "kilometers",
      primaryAreaUnit: "sqmeters",
      secondaryAreaUnit: "hectares",
      localization: "pt_BR",
      activeColor: "#b83d22",
      completedColor: "#b83e00",
    });
    measureControl.addTo(_map);
    _map.on("measurefinish", () => {
      const printButton = document.createElement("button");
      printButton.title = "Imprimir medidas";
      printButton.classList.add("print-measures");
      printButton.innerHTML = "🖶 Imprimir";
      printButton.onclick = window.printMeasures;
      document.getElementsByClassName("tasks");
      const li = document.createElement("li");
      li.appendChild(printButton);
      setTimeout(() => {
        document
          .getElementsByClassName("leaflet-popup-content")[0]
          .children[0].children[3].appendChild(li);
      }, 1000);
    });

    _map.pm.addControls({
      position: "topleft",
      drawCircle: false,
      drawMarker: false,
      drawPolyline: false,
      cutPolygon: false,
      drawCircleMarker: false,
      drawRectangle: false,
      editMode: false,
      dragMode: false,
    });
    _map.pm.setLang("pt_br");

    _map.on("pm:create", (e) => {
      const fg = L.featureGroup();
      _map.eachLayer((l) => {
        if (l instanceof L.Polygon && l.pm) {
          fg.addLayer(l);
        }
      });

      const geojson = fg.toGeoJSON().features;
      const request = {
        geojson: geojson[geojson.length - 1].geometry,
        filters: window.pointsFilterValues,
      };
      GeolocationService.fetchDataByArea(request).then((resp) => {
        if ((resp?.count || 0) > 0) {
          const quantidade = resp.count;
          const area = parseFloat(resp.areaHA.toFixed(2));

          let tablePresentation = "";

          if (LoginService.getApp() === "mapboi") {
            const areaFormatada = parseFloat(resp.areaHA.toFixed(2)).toLocaleString("pt-BR");
            const ece = (area * 10000) / 13;
            const fluxo = ece * 1.8;

            const tableRowAreaDasUnidadesPiscicolas = `<tr><td>Área: (hectares): </td><td><strong>${areaFormatada}</strong></td></tr>`;
            const tableRowNumeroDeUnidadesPiscicolas = `<tr><td>Confinamento: </td><td><strong>${quantidade}</strong></td></tr>`;
            const tableRowECE = `<tr><td>Estimativa de capacidade estática (cabeças): </td><td><strong>${parseFloat(
              ece.toFixed(0)
            ).toLocaleString("pt-BR")}</strong></td></tr>`;
            const tableRowFluxo = `<tr><td>Estimativa de fluxo (cabeças por ano): </td><td><strong>${parseFloat(
              fluxo.toFixed(0)
            ).toLocaleString("pt-BR")}</strong></td></tr>`;

            tablePresentation = `<table>${tableRowNumeroDeUnidadesPiscicolas}${tableRowAreaDasUnidadesPiscicolas}${tableRowECE}${tableRowFluxo}</table>`;
          } else {
            const parcela = (parseFloat(resp.areaINA) / quantidade).toFixed(2);
            const tableRowAreaDasUnidadesPiscicolas = `<tr><td>Área das unidades piscícolas (hectares): </td><td>${area}</td></tr>`;
            const tableRowTaxaDeOciosidadeMedia = `<tr><td>Tx. de ociosidade média (área fora de produção) (%): </td><td>${parcela}</td></tr>`;
            const tableRowNumeroDeUnidadesPiscicolas = `<tr><td>Número de unidades piscícolas: </td><td>${quantidade}</td></tr>`;
            tablePresentation = `<table>${tableRowNumeroDeUnidadesPiscicolas}${tableRowAreaDasUnidadesPiscicolas}${tableRowTaxaDeOciosidadeMedia}</table>`;
          }

          tablePresentation +=
            '<button title="Imprimir cálculo" class="print-measures" onclick="window.printDataCalc()">🖨 Imprimir</button>';

          e.layer.bindPopup(tablePresentation).openPopup();
        } else {
          e.layer.bindPopup("Nenhuma unidade produtiva nesta área").openPopup();
        }
      });
    });
    L.control.scale({ metric: true, imperial: false }).addTo(_map);
    let streetLayer = L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
        token,
      {
        maxZoom: 20,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }
    ).addTo(_map);

    const googleSat = L.tileLayer(
      "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    );

    setLayer([streetLayer, googleSat]);
    const url = window.location.host.includes("localhost")
      ? "http://localhost:5001/bussola/wms"
      : "/bussola/wms";

    setTimeout(() => {
      toggleShowAlreadyVisitedPoints(true);
    }, 1000);

    const baseMaps = {};

    const textslider = {
      mapboi:
        "Confinamentos (pontos)</br><table>" +
        '<tr><td style="text-align: center;"><img src="../images/red.png" /></td><td>Pequenos: 0-2 ha</td></tr>' +
        '<tr><td style="text-align: center;"><img src="../images/yellow.png" /></td><td>Médios: 2-10 ha</td>' +
        '</tr><tr><td style="text-align: center;"><img src="../images/blue.png" /></td><td>Grandes: 10-125 ha</td></tr></table>',
      mapeixe:
        "Unidades piscícolas (pontos)</br><table>" +
        '<tr><td style="text-align: center;"><img src="images/red.png" /></td><td>Pequenas: 0-5 ha</td></tr>' +
        '<tr><td style="text-align: center;"><img src="images/yellow.png" /></td><td>Médias: 5-20 ha</td>' +
        '</tr><tr><td style="text-align: center;"><img src="images/blue.png" /></td><td>Grandes: 20-125 ha</td></tr></table>',
    };

    const propsOverlay = {
      mapeixe: "Unidades piscícolas (desenhos)",
      mapboi: "Confinamentos (desenhos)",
    };

    const perimetersLayer = L.tileLayer
      .wms(url, {
        layers: geoserverConfig.perimetersLayer.join(","),
        transparent: true,
        format: "image/png",
      })
      .setZIndex(100)
      .addTo(_map);

    const overlayMaps = {
      '<img src="images/Municpios.png" /> Municípios': perimetersLayer,
    };
    
    const polygonsLayer = L.tileLayer
      .wms(url, {
        layers: geoserverConfig.polygonsLayer.join(","),
        transparent: true,
        format: "image/png",
      })
      .setZIndex(300)
      .addTo(_map);

    overlayMaps[propsOverlay[LoginService.getApp()]] = polygonsLayer;

    const pointsLayer = L.tileLayer
      .betterWms(url, {
        layers: geoserverConfig.pointsLayer.join(","),
        transparent: true,
        format: "image/png",
      })
      .addTo(_map);
    pointsLayer.setZIndex(400);
    pointsLayer.on("remove", () => {
      toggleShowAlreadyVisitedPoints(false);
    });
    pointsLayer.on("add", () => {
      toggleShowAlreadyVisitedPoints(true);
    });
    setDataLayer(pointsLayer);

    overlayMaps[textslider[LoginService.getApp()]] = pointsLayer;

    L.control.layers(baseMaps, overlayMaps, { collapsed: false }).addTo(_map);
    routingControl.current = L.Routing.control({
      language: "pt_BR",
      formatter: new L.Routing.Formatter({
        language: "pt_BR",
      }),
      router: L.routing.mapbox(token),
      plan: L.Routing.plan(waypoints, {
        createMarker: function (i, wp) {
          return L.marker(wp.latLng, {
            draggable: true,
            icon: L.icon.glyph({ glyph: String.fromCharCode(65 + i) }),
          });
        },
        geocoder: L.Control.Geocoder.nominatim(),
        language: "pt_BR",
      }),
      showAlternatives: true,
      addWaypoints: true,
      draggableWaypoints: true,
      fitSelectedRoutes: true,
      show: false,
      altLineOptions: {
        styles: [
          { color: "black", opacity: 0.15, weight: 9 },
          { color: "white", opacity: 0.8, weight: 6 },
          { color: "blue", opacity: 0.5, weight: 2 },
        ],
      },
    }).addTo(_map);

    _map.on("moveend", (event) =>
      alreadyVisitedPointsUpdatedListener.publish({ location: "move" })
    );

    _map.on("popupopen", (event) => {
      _map.setView(event.target._popup._latlng, _map.getZoom(), {
        animate: true,
        duration: 1,
      });
    });

    setMap(_map);
    window.map = _map;
    window.marker = L.marker({ lat: 0, lng: 0 }, { icon }).addTo(window.map);

    return () => _map.remove();
  }, [
    routingControl,
    routeContent,
    routeContent1,
    routeContent2,
    mapContainer,
    geoserverConfig,
    setCheckboxRoute,
  ]);

  useEffect(() => {
    if (mapReady) {
      setShowMarker(true);
    } else {
      setShowMarker(false);
    }
  }, [map, mapReady]);

  useEffect(() => {
    (async () => {
      const userData = LoginService.customerData();
      setUser(userData);
    })();

    navigator.geolocation.watchPosition(
      (pos) => {
        setLatitude(pos?.coords?.latitude ?? 0);
        setLongitude(pos?.coords?.longitude ?? 0);
      },
      (err) => {
        log("useEffect").error(
          "error trying to watch user location {}",
          err.message
        );
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
  }, []);

  useEffect(() => {
    if (user) {
      GeolocationService.getCustomerBasedLayers(user).then(setGeoserverConfig);
    }
  }, [user]);

  useEffect(() => {
    if (!updateLocationDebounce && !window.stopLocationTracking) {
      const debounce = setTimeout(() => {
        if (showMarker && (Object.keys(map._layers) || []).length > 0) {
          window.marker.setLatLng(new L.latLng(latitude, longitude));
        }
        setUpdateLocationDebounce(null);
      }, LOCATION_DEBOUNCE_TIME);
      setUpdateLocationDebounce(debounce);
    }
  }, [latitude, longitude, map, showMarker, updateLocationDebounce]);

  const changeStyle = useCallback(() => {
    setBaseLayer(!baseLayer);
    if (baseLayer) {
      layer[1].addTo(map);
      layer[0].remove();
    } else {
      layer[0].addTo(map);
      layer[1].remove();
    }
    setMap(map);
    window.map = map;
  }, [baseLayer, layer, map]);

  const handleLogin = useCallback(() => {
    (async () => {
      LoginService.login();
    })();
  }, []);

  const handleLogout = useCallback(() => {
    (async () => {
      LoginService.logout();
    })();
  }, []);

  const renderLoginLogoutButton = () => {
    if (LoginService.getMode() === "logged") {
      return (
        <button id="logout" onClick={handleLogout}>
          Sair(Logout)
        </button>
      );
    }

    return (
      <button id="login" onClick={handleLogin}>
        Entrar(Login)
      </button>
    );
  };

  const handleFlyToClick = useCallback(() => {
    map.flyTo({ lat: latitude, lng: longitude }, 14);
  }, [latitude, longitude, map]);

  return (
    <div
      id="map"
      style={{ padding: 0, margin: 0, width: "100%", height: "100vh" }}
      ref={(el) => (mapContainer.current = el)}
    >
      {renderLoginLogoutButton()}
      <img
        className="base-layer-selector"
        onClick={changeStyle}
        src={baseLayer ? "images/satellite.png" : "images/streets.png"}
        alt="change layer view"
      />
      <p
        id="base-layer-title"
        style={{
          position: "fixed",
          bottom: 5,
          left: 10,
          color: baseLayer ? "white" : "black",
          letterSpacing: 0,
          fontWight: "bold",
          zIndex: 9999,
        }}
      >
        {baseLayer ? "Satélite" : "Estradas"}
      </p>
      <img
        src="images/my_location.png"
        style={{
          position: "fixed",
          zIndex: 9999,
          bottom: "2%",
          right: "0.5rem",
          width: "25px",
          height: "25px",
          backgroundColor: "#ffffff",
          border: "solid 3px rgb(177, 177, 177)",
          padding: "4px",
          borderRadius: "9px",
        }}
        alt="my location"
        onClick={handleFlyToClick}
        id="fly-to"
      />
      <button id="toggleMenuButton" type="button" className="toggleMenu">
        |||
      </button>
    </div>
  );
};

export default Map;
