import React, { useCallback, useEffect, useState } from 'react';
import {
  detailsUpdatedListener,
  loadingAnnotationListener
} from '../../listeners';
import DetailsService from '../../services/DetailsService';
import AnnotationField from './AnnotationField';
import './Details.css';

const Details = () => {
  const [show, toggleVisibility] = useState(false);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  window.toggleAnnotations = toggleVisibility;

  loadingAnnotationListener.subscribe('Details', message => {
    setLoading(message);
  });

  detailsUpdatedListener.subscribe('Details', message => {
    setDetails(message);
    toggleVisibility(false);
    toggleVisibility(true);
  });

  useEffect(() => {
    if (details) {
      setLoading(false);
    }
  }, [details]);

  useEffect(() => {
    if (show) {
      (async () => {
        const foundDetails = await DetailsService.find(
          window.selectedAnnotationLocation
        );
        if (foundDetails) {
          setDetails(foundDetails);
        }
      })();
    }
  }, [show]);

  const handleCloseAnnotations = useCallback(() => {
    toggleVisibility(false);
  }, []);

  const handleAddAnnotationClick = useCallback(() => {
    const updatedDetails = {
      ...details,
      annotations: details.annotations || []
    };

    updatedDetails.annotations.push({
      name: '',
      value: '',
      isPrivate: false
    });

    setDetails(updatedDetails);
  }, [details]);

  const renderAnnotation = annotation => {
    const id = annotation.id ?? 'new-annotation';

    return (
      <AnnotationField
        annotation={annotation}
        key={id}
        id={id}
        detailsId={details.id}
      />
    );
  };

  const renderAddAnnotationButton = () => {
    if (loading) {
      return (
        <img
          className="old-loading"
          alt="loading"
          src="images/loading-old.gif"
        />
      );
    }

    return (
      <button
        className="add-annotation"
        onClick={handleAddAnnotationClick}
        title="Nova anotação"
      >
        +
      </button>
    );
  };

  const render = () => {
    if (show) {
      return (
        <div className="annotations-background">
          <div className="annotations-container">
            <h3>Anotações sobre este ponto</h3>
            <div className="annotations-header">
              <p title="Utilize o &#10003; para Salvar e o &times; para cancelar ou excluir uma anotação">
                Salvar/Excluir
              </p>
              <p>Nome do Campo</p>
              <p>Anotação</p>
              <p>Anotação Pública?</p>
            </div>
            <button
              className="close-annotations"
              onClick={handleCloseAnnotations}
            >
              &times;
            </button>
            <div className="annotation-list">
              {(details?.annotations || []).map(renderAnnotation)}
            </div>
            {renderAddAnnotationButton()}
          </div>
        </div>
      );
    }

    return '';
  };

  return render();
};

export default Details;
