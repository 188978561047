/* eslint-disable no-undef */
/* eslint-disable no-console */

const isDebugEnabled = process.env.REACT_APP_DEBUG === 'enabled';

const LOG_TYPE_BIND = {
  INFO: console.log,
  ERROR: console.error,
  WARN: console.warn,
};

const debug = () => {
  if (isDebugEnabled)
    return (logType, message, ...args) => {
      const print = LOG_TYPE_BIND[logType];
      let msg = `web-app :: ${message}`;

      for (const arg of args) {
        if (typeof arg === 'object')
          msg = msg.replace('{}', JSON.stringify({ arg }));
        else msg = msg.replace('{}', arg);
      }

      print(msg);
    };

  return () => {};
};

export const LoggerFactory = (source) => (method) => {
  const logger = debug();

  const print = (logType, message, ...args) => {
    const formatted = `${new Date().toUTCString()} - ${logType} :: ${source} :: ${method} :: ${message}`;
    logger(logType, formatted, ...args);
  };

  return {
    info(message, ...args) {
      print('INFO', message, ...args);
    },

    warn(message, ...args) {
      print('WARN', message, ...args);
    },

    error(message, ...args) {
      print('ERROR', message, ...args);
    },
  };
};
