import { loadingPrintListener } from "../listeners";
import { LoggerFactory } from "../utils/loggerFactory";
import LoginService from "./LoginService";
const log = LoggerFactory("ReportService");

const baseURL = window.location.host.includes("localhost")
  ? "http://localhost:5001/reports"
  : "/reports";

let controller = new AbortController();
const timeout = () => setTimeout(() => controller.abort(), 100000);
const resetTimeout = () => {
  controller = new AbortController();
  clearTimeout(timeout);
};

const ReportService = {
  async printReport(reportData) {
    timeout();
    return fetch(baseURL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoginService.getAccessToken()}`,
      },
      body: JSON.stringify(reportData),
      signal: controller.signal,
    }).catch((error) => {
      log("printReport").error(
        "error trying print report: {}",
        error.message ?? error
      );
      resetTimeout();
      return null;
    });
  },

  async verifyHowManyReports() {
    timeout();
    return fetch(baseURL, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${LoginService.getAccessToken()}`,
      },
      signal: controller.signal,
    }).catch((error) => {
      log("verifyHowManyReports").error(
        "error trying to verify how many reports remains: {}",
        error.message ?? error
      );
      resetTimeout();
      return null;
    });
  },

  async sendReportByEmail(to, report) {
    if (!to || !report) {
      log("sendReportByEmail").warn(
        "No report or receiver setted. to={} report={}",
        to,
        report
      );

      return null;
    }
    loadingPrintListener.publish(true);
    return fetch(`${baseURL}/send-by-email`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LoginService.getAccessToken()}`,
      },
      body: JSON.stringify({
        to,
        subject: "Relatório",
        body: `Seu relatório solicitado em ${LoginService.getApp()}`,
        attachment: {
          content: report.match(/^data:.+\/(.+);base64,(.*)$/)[2],
          filename: "attachment.pdf",
          type: "application/pdf",
          disposition: "attachment",
        },
      }),
      signal: controller.signal,
    })
      .then(() => loadingPrintListener.publish(false))
      .catch((error) => {
        log("sendReportByEmail").error(
          "error trying to send report by e-mail: {}",
          error.message ?? error
        );
        loadingPrintListener.publish(false);
        resetTimeout();
      });
  },
};

export default ReportService;
